//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { appDownload } from "../api";
export default {
  name: "ApplyRight",
  data() {
    return {
      uid: JSON.parse(sessionStorage.getItem("user")).uid,
      form: {
        name: JSON.parse(sessionStorage.getItem("user")).username,
        email: JSON.parse(sessionStorage.getItem("user")).email,
        text: "",
      },
      rules: {
        name: [
          {
            required: "true",
            message: this.$t("authority.namePlaceholder"),
            trigger: "blur",
          },
        ],
        text: [
          {
            required: "true",
            message: this.$t("authority.textPlaceholder"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmit(formName) {
      var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("uid", this.uid);
      formData.append("nickname", this.form.name);
      formData.append("nickname", this.form.email);
      formData.append("shuoming", this.form.text);
      this.$refs[formName].validate((valid) => {
        console.log(valid);
        if (valid) {
          // this.$router.replace({
          //   name: "success",
          //   query: { type: "authority" },
          // });
          fetch("https://chinapavilion.com.cn/index/meizi/apply_download", {
            method: "post",
            body: formData,
          })
            .then((response) => response.json())
            .then((result) => {
              console.log("aaa1", result);
              this.$message(result.data);
              if (result.code == 1) {
                this.$router.replace({
                  name: "success",
                  query: { type: "authority" },
                });
              }
            })
            .catch((error) => {
              this.$message.error(error.data.info);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
